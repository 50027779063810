/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */



var body = 'body,html';
var scrollTop;

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



        $('.slider_row').slick({

        dots: false,
        infinite: true,
        speed: 1000,
              slidesToShow: 3,
              slidesToScroll: 1,
        centerMode: true,
        variableWidth: true,
        //autoplay: true,
        //autoplaySpeed: 2500,
         arrows:false,
         pauseOnFocus:false,
         pauseOnHover:false,




      });




jQuery('.gal_der').on('click', function(ev){
  ev.preventDefault();
  $(this).parents('.cont_slick').find('.slick_slider').slick('slickNext');
  //console.log("CLICK!!!");

});

jQuery('.gal_izq').on('click', function(ev){

  ev.preventDefault();
  $(this).parents('.cont_slick').find('.slick_slider').slick('slickPrev');
  //console.log("CLICK!!!");


});


        /*
$('.responsive').slick({
  dots: true,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1e
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});
        */


    //PRE NEWS

            if(  jQuery('.window_sin_news')  ){

              jQuery('.window_todo').height(jQuery(window).height() - jQuery('.banner').height() - jQuery('.nav_header_page').outerHeight() + 35  );

            }


            //PRE NEWS

            if( jQuery('.window_todo_news')  ){

              // jQuery('.window_todo_news').height(jQuery('.window_todo_news').height() + jQuery('.banner').height()    );
              jQuery('.window_todo_news').css('margin-top', -1*jQuery('.banner').outerHeight() +'px' );


            }




     /*   jQuery('.menu_header_page li').eq(0).find('.op_menu').on('click', function(ev){
          ev.preventDefault();

        });
        */


        //EASESCROLL jquery.scroll-with-ease

        jQuery('html').scrollWithEase();


        jQuery('.bot_menu').on('click', function(ev){
          ev.preventDefault();
          jQuery(this).toggleClass('cerrar');
          jQuery(this).prev().toggleClass('exit');

        });


        if(jQuery('.img_parallax').length>0 ){

            var image = document.getElementsByClassName('img_parallax');
            new simpleParallax(image);

        }



        //img_parallax


       resizea();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


jQuery(window).on('resize', function(){

    console.log("resizea");

    resizea();

});

function resizea(){



if( jQuery('.caja_feat')  ){



  var contLeft = jQuery('.container').offset();


  jQuery('.caja_feat').css( 'left', contLeft.left+"px" );

  var margR =  parseInt(jQuery('.container').css('margin-left'))  ;

   jQuery('.caja_feat').width( jQuery('.container').width() + margR + 30  );

}





}
function amountscrolled(){
    var winheight = jQuery(window).height()
    var docheight = jQuery(document).height()
    var scrollTop = jQuery(window).scrollTop()
    var trackLength = docheight - winheight
    var pctScrolled = Math.floor(scrollTop/trackLength * 100) // gets percentage scrolled (ie: 80 NaN if tracklength == 0)
   // console.log(pctScrolled + '% scrolled')
    return pctScrolled;
}
jQuery(window).on('scroll', function(){

    scrollTop = jQuery(window).scrollTop();

    console.log("SCROLLTOP " + scrollTop);


    if(scrollTop > jQuery('.banner').outerHeight(true) ){

    //  jQuery('.banner').addClass('minibar');

    jQuery('.banner').addClass('minibar_nu');

    }else{

    //  jQuery('.banner').removeClass('minibar');

    jQuery('.banner').removeClass('minibar_nu');

    }

    var scrolled = amountscrolled();

    console.log("SCROLLED > " + scrolled/10 );

    jQuery('.img_baix').css('opacity', (1-(scrolled/50)) );


    jQuery('.cont_ruido').css('opacity', (1-(scrolled/50)) );

});




//MENÚ

function cargoPage(ev){

  ev.preventDefault();

  //alert(jQuery(this).attr('href')+" / "+ );

 //alert(MyAjax.url);

 var pageID = jQuery(this).data('id');
 var pageSlug = jQuery(this).data('slug');
  var edicionID = jQuery(this).data('edicionid');
 console.info(MyAjax.url);

/*
 jQuery.ajax({
    url: MyAjax.url,
    type: 'post',
    data: {
      action: 'cambiar_page'
    },
    success: function( result ) {
      alert( result );
    }
  })
*/


  jQuery.post(MyAjax.url, { nonce : MyAjax.nonce, action : 'cambiar_page' ,  pageID:  pageID ,slug: pageSlug , edicionID : edicionID }, function(response) {

      console.log("RESPONSE >");
      console.log(response);

       var jQuerycontent = jQuery(response);



        console.log("jQuerycontent");
        console.log(jQuerycontent);



        jQuery('#contenido_importante').html(jQuerycontent);



  });



}
//jQuery('.menu_hor a').on('click', cargoPage);

function cargoPageNew(ev){

  ev.preventDefault();


 //console.log( "INDEX OF " + jQuery(this).parent('.menu_both').find('li').index( jQuery(this).parent() )  );



 if( jQuery('body').hasClass('home') &&  ( jQuery(this).data('pos')==1 )  ){

   // alert("!!!!");
   var goTo = jQuery('.nav_header_page').offset();
   goTo = goTo.top -  (jQuery('.banner').outerHeight() + 100);


   jQuery(body).animate( { scrollTop : goTo }, 400 );


 }else{

    var href = jQuery(this).attr('href');
    jQuery('.div_loading').addClass('oculto_load');
    window.location.href = href;

 }





}
jQuery('.op_menu').on('click', cargoPageNew );


window.onload = function(){

  jQuery('.div_loading').removeClass('oculto_load');

  if(jQuery('.slick-slide').length>=1 ){


      jQuery('.slick-slide').height(jQuery('.slick-slide').parent().height() );

      jQuery('.slick-slide').each(function(){

       // jQuery(this).height( jQuery(this).parent().height() );

        //console.log(   jQuery(this).height()   );

      });




  }


  resizea();

  if(jQuery('.th_fancy')){



        jQuery('.click_video').on('click', function(ev){

          ev.preventDefault();
          var miVideo = jQuery(this).attr('href');
          jQuery(miVideo).addClass('fancy_on');



          var altIframe = -1* (jQuery('.fancy_on').find('iframe').height()/2  +40)  ;

          jQuery('.bot_cerrar_fancy').css('transform',' translateY('+ altIframe  +'px )' );


        });

        jQuery('.th_fancy').on('click', function(ev){

          jQuery(this).removeClass('fancy_on');

        });





  }



}

function resize(){

  if(jQuery('.th_fancy')){

        jQuery('.th_fancy').each(function(){

          var anch = jQuery(this).find('iframe').attr('width');
          var alt = jQuery(this).find('iframe').attr('height');

          var prop = anch/alt;



          jQuery(this).find('iframe').width(jQuery(window).width() * 0.8 +"px" );

          jQuery(this).find('iframe').height(jQuery(window).width() * 0.8 /prop +"px" );


          jQuery(this).find('iframe').attr('width',  jQuery(window).width() * 0.8 ) ;

          jQuery(this).find('iframe').attr('height', jQuery(window).width() * 0.8 / prop ) ;


         // jQuery(this).addClass('d-none');

        });






  }



}
